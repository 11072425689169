.Content {
    height: 100vh;
    margin: 0 100px;
}

@media only screen
and (max-width: 720px) {
    .Content {
        margin: 0 20px;
    }
}